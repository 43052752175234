.crypto-exchange-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  color:white;
  .content {
    height:100%;
    display:flex;
    flex-direction: row;
    @media (max-width: 900px) {
      flex-direction: column;
    }
    align-items:center;
    justify-content:center;
    padding: 20px;
    .left-section{
      flex: 1;
      @media (max-width: 900px) {
        flex:none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title{
        font-size: 64px;
        line-height: 70px;
        font-weight: 600;
        align-items: center;
        display: flex;
        flex-direction:column;
        position: relative;
        .left-logo{
          position: absolute;
          top: -200px;
          left: -100px;
          width: 550px;
        }
        @media (max-width: 900px) {
          .left-logo{
            position: absolute;
            top: -120px;
            left: -75px;
            width: 320px;
          }
        }
      }
      @media (max-width: 900px) {
        .title{
          font-size: 32px;
          line-height: 35px;
          font-weight: 400;
        }
      }
    }
    .right-section{
      flex: 1;
      @media (max-width: 900px) {
        flex:none;
      }
      align-items: center;
      display: flex;
      flex-direction: column;
      .description{
        font-size: 17px;
        font-weight: 400;
        margin-top: 20px;
        max-width: 300px;
        text-align: center;
        line-height: initial;
        position: relative;
        @media (max-width: 900px) {
          color:#dbbfd9;
        }
        .right-back{
          position: absolute;
          top:71px;
          left: -61px;
          width: 480px;
          z-index: 0;
        }
      }
      .qrcode-section{
        padding:30px;
        background:white;
        border-radius:10px;
        margin-top: 20px;    
        z-index: 1000;    
      }
    }
    .result-img{
      
    }
    .avatar-img{
      position: absolute;
      top:100px;
    }
    @media only screen and (max-width: 450px) {
      .result-img {
        width:100%
      }
    }
    
    .result-screen-text{
      margin-top: 20px;
      text-align: center;
    }
    
    .btn-gradient {
      border-radius: 50em;
      padding:12px;
      border: 2px solid transparent;
      background:white;
      margin-top: 20px;
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:20px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }
  .exchange{
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .switch-img{
      width: 48px;
      margin: 32px 0;
      cursor: pointer;
    }
    .rate-value{
      color: #dbbfd9;
      font-size: 14px;
      margin: 32px 0;
    }
    .btn-exchange{
      height: 48px;
      width: 300px;
      border: none;
      border-radius: 100px;
      color: #36155c;
      font-size: 16px;
    }
    .crypto-card{
      width: 400px;
      @media (max-width: 450px) {
        width: 100%;
      }
      padding: 20px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      .top-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .ctypto-selector-cover{
          cursor: pointer;
          .exchange-title{
            font-size: 16px;
            color: #dbbfd9;
            margin-left: 42px;
          }
          .ctypto-name{
            font-size: 16px;
            color: #dbbfd9;
            margin-left: 42px;
          }
          .ctypto-selector{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 5px 0;
            .btc-img{
              width: 32px;
            }
            .name{
              font-size: 32px;
              margin-left: 10px;
            }
            .arrow-img{
              width: 14px;
              height: 8px;
              margin-left: 10px;
            }
          }
        }
        .exchange-value{
          font-size: 32px;
          background: transparent;
          border: none;
          color: white;
          text-align: right;
        }
      }
      .separator{
        margin: 20px 0 ;
        height: 1px;
        width: 100%;
        border-top:1px solid rgba(217, 217, 217, 0.1)
      }
      .bottom-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .balance{
          font-size: 16px;
          color: #dbbfd9;
        }
        .cypto-value{
          display: flex;
          flex-direction: row;
          font-size: 16px;
          color: white;
          .btn-max{
            border-radius: 20px;
            margin-left: 10px;
            border: 1px solid white;
            background: transparent;
            color: white;
            padding: 0 10px;
          }
        }
      }
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:20px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }
  .token-row{
    display: flex;
    flex-direction: row;
    .token-img{
      width: 40px;
    }
  }

}