.home-area {
  position: relative;
  height:100%;
  width:100%;
  background: white;
  justify-content: center;
  align-items: center;
  .top-section{
    padding: 50px 20px 50px 20px;
    background-size: cover;
    @media (min-width: 900px) {
      padding: 50px 100px 50px 100px;
    }
    @media (min-width: 1200px) {
      padding: 50px 200px 50px 200px;
    }
    .top-logo{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .top-content{
      padding-top: 50px;
      .title{
        font-size: 48px;
        color: #0E113A;
        line-height: 66px;
        font-weight:500;
      }
      .description{
        font-size: 20px;
        color: #3F3F51;
        line-height: 28px;
        font-weight: 400;
        margin-top: 20px;
      }
      .top-fill-btn{
        background:#3458E0;
        box-shadow: 0 5px 10px 0 rgba(30, 61, 116, 0.25);
        border-radius: 50em;
        border: 2px solid transparent;
        height: 48px;
        color:white;
        margin-top: 20px;
        width: 100%;
        transition: background-color 0.3s, color 0.3s;
        &:hover {
          background-color: #1841d5;
        }
        @media (min-width: 500px) {
          width: 327px;
        }
      }
      .top-outline-btn{
        box-shadow: 0 5px 10px 0 rgba(30, 61, 116, 0.25);
        border-radius: 50em;
        background:transparent;
        border: 2px solid #3458E0;
        height: 48px;
        color:#3458E0;
        width: 100%;
        margin: 20px 0 20px 0;
        transition: background-color 0.3s, color 0.3s;
        &:hover {
          background-color: #ffffff;
        }
        @media (min-width: 500px) {
          width: 327px;
        }
      }
      .top-img{
        width: 100%;
        @media (min-width: 1110px) {
          width: 80%;
        }
      }
    }
  }
  .middle-content{
    padding: 50px 20px 50px 20px;
    @media (min-width: 900px) {
      padding: 50px 100px 50px 100px;
    }
    @media (min-width: 1200px) {
      padding: 50px 200px 50px 200px;
    }
    .title{
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 40px;
      color:#192670;
      margin-bottom: 30px;
      line-height: 55px;
    }
    .middle-left{
      border: 1px solid #3458E0;
      border-radius:16px;
      padding:20px 20px 0 20px;
      margin-bottom: 20px;
      @media (min-width: 900px) {
        padding:50px 50px 0 50px;
        margin-bottom: 0px;
      }
      .short_horizontal_line{
        width: 50px;
        border-bottom: 3px solid #3458E0;
        margin-top: 20px;
      }
      .steps{
        font-size: 20px;
        color: #3458E0;
        font-weight: 500;
      }
      .step{
        display: flex;
        flex-direction: row;
        margin: 25px 0 50px 0;
        .number{
          color: #192670;
          width: 50px;
          font-size: 32px;
        }
        .description{
          font-size:16px;
          color: #333333;
          font-weight: 350;
          margin-left: 10px;
        }
      }
    }
    .img-group{
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo{
        width:30%;
      }
      .google{
        width: 60%;
        margin-top: 20px;
        cursor: pointer;
        transition: width 0.3s;
        &:hover {
          width: 62%;
        }
      }
      .apple{
        width: 60%;
        margin-top: 20px;
        cursor: pointer;
        transition: width 0.3s;
        &:hover {
          width: 62%;
        }
      }
    }
  }
  .bottom-section{
    padding: 50px 20px 50px 20px;
    background: #EDF3FF;
    
    @media (min-width: 900px) {
      padding: 50px 100px 50px 100px;
    }
    @media (min-width: 1200px) {
      padding: 50px 200px 50px 200px;
    }
    .title{
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 40px;
      color:#192670;
      margin-bottom: 30px;
      line-height: 55px;
    }
    .demo{
      cursor: pointer;
      padding: 12px;
      width: 100%;
      border-radius: 8px;
      transition: background-color 0.3s;
      .demo-img-cover{
        overflow: hidden;
        border-radius: 8px;
      }
      &:hover {
        background-color: #DBE3FB;
        .demo-img-cover{
          overflow: hidden;
          border-radius: 8px;
          .demo-img{
            opacity: 0.7;
            transform: scale(1.25);
            border-radius: 8px;
            transition: opacity 0.3s,transform 0.3s;
          }
        }
      }
      .demo-title{
        font-size:18px;
        color:#000000;
        font-weight: 600;
        padding: 4px;
      }
      .description{
        font-size: 14px;
        color: #666666;
        padding: 4px;
      }
      .demo-img{
        width: 100%;
        border-radius: 8px;
        transition: opacity 0.3s, transform 0.3s;
      }
    }
    
  }
  .bottom-logo-line{
    background: #0E113A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px 50px 20px;
    @media (min-width: 900px) {
      padding: 50px 100px 50px 100px;
    }
    @media (min-width: 1200px) {
      padding: 50px 200px 50px 200px;
    }
    .left-bottom-logo{
      width: 148px;
      height: 32px;
    }
    .right-bottom-logo{
      width: 162px;
      height: 22px;
    }
  }
}