.visa-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  li {
    list-style-type: none;
    padding-left: 20px;
    font-size:16px;
    margin-top: 5px;
  }
  li:before {
    content: ""; color: #40bbfd;
    float: left;
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    width: 20px;
    margin-left: -20px;
    font-size:24px;
  }
  .content {
    height:100%;
    align-items:center;
    justify-content:center;
    display: flex;
    flex-direction: row;
    padding:30px;
    .content-body{
      display:flex;
      align-items:center;
      justify-content:center;
      overflow-y: auto;
      max-height: calc(100vh - 70px - 85px);
      padding: 20px 5px;
      @media only screen and (max-width: 600px) {
        justify-content: flex-start;
        flex-direction: column;
      }
    }
    .description{
      font-size: 32px;
      font-weight: 400;
      line-height: initial;
      width:35%;
      color:#28226a;
    }
    @media only screen and (max-width: 800px) {
      .description {
        width:100%;
        font-size: 20px;
      }
    }
    .result-screen-text{
      margin-top: 20px;
      text-align: center;
    }
    .qrcode-section{
      padding:30px;
      background:white;
      border-radius:10px;
      display: flex;
      flex-direction: row;
      margin-left: 40px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      .info-text{
        margin-left: 20px;
        color:black;
        .title{
          font-size: 24px;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 800px) {
          .title {
            margin-top: 10px;
          }
        }
        .info{
          font-size: 16px;

        }
      }
    }
    @media only screen and (max-width: 800px) {
      .qrcode-section {
        flex-direction: column;
        margin-left: 0px;
        width:100%;
        align-items: center;
        margin-top: 10px;
      }
    }
    .info-section{
      padding:30px;
      background:white;
      border-radius:10px;
      width:80%;
      box-shadow: 0 12px 6px rgba(0,0,0,0.1);
      .info-title{
        color:black;
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
      }
      .info-content{
        display: flex;
        .info-left{
          flex: 1;
          .input-form{
            width: 100%;
            margin-top: 15px;
            .input-label{
              color:#777777;
              font-size: 14px;
            }
            .input-text{
              margin-top: 5px;
              border-radius: 10px;
              background: #ecefff;
            }
          }
        }
        .info-right{
          margin-left: 20px;
          flex: 1;
          .input-form{
            width: 100%;
            margin-top: 15px;
            .input-label{
              color:#777777;
              font-size: 14px;
            }
            .input-text{
              margin-top: 5px;
              border-radius: 10px;
              background: #ecefff;
            }
          }
        }
      }
      .btn-box{
        display: flex;
        justify-content: center;
        .btn-submit {
          border-radius: 50em;
          padding:12px;
          border: 2px solid transparent;
          background:#2c45a4;
          margin-top: 20px;
          color:white;
          width:200px;
          &:hover{
            background:#17329b;
          }
        }
      }
      
    }
    @media only screen and (max-width: 800px) {
      .info-section {
        width: 100%;
      }
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:20px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .content {
      flex-direction: column;
      padding:20px
    }
  }

}