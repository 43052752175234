.night-club-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  color:white;
  .content {
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    padding: 20px;
    .result-img{
      
    }
    .avatar-img{
      position: absolute;
      top:100px;
    }
    @media only screen and (max-width: 450px) {
      .result-img {
        width:100%
      }
    }
    .description{
      font-size: 24px;
      font-weight: 400;
      margin-top: 20px;
      max-width: 300px;
      text-align: center;
      line-height: initial;
    }
    .result-screen-text{
      margin-top: 20px;
      text-align: center;
    }
    .qrcode-section{
      padding:30px;
      background:white;
      border-radius:10px;
      margin-top: 20px;
    }
    .btn-gradient {
      border-radius: 50em;
      padding:12px;
      border: 2px solid transparent;
      background:white;
      margin-top: 20px;
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:20px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }

}