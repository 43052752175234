.age {
  .btn-custom{
    background: #4a56a7; 
    box-shadow: 0px 5px 10px rgba(30,  61, 116, 0.25);
    border-radius: 100px;
    color: white;
    padding: 12px 42px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 232px;
    &:hover {
      background: #3d4ba2;
      color: white;
    }
  }
}
canvas{
  position: absolute;
  width: 328px;
  height: 214px;
}